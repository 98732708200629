import React from 'react'
import './Program.css'
import { programsData } from '..//..//data//programsData'
import rightArrow from'..//..//assets//rightArrow.png'

const Program = () => {
    return (
        <div className='programs' id='program'>
            <div className="program-header">
                <span className='stroke-text'>Explore Our</span>
                <span>Programs</span>
                <span className='stroke-text'>to Shape you</span>
            </div>
            <div className="program-catagories">
                {programsData.map((program) => {
                    return(
                        <div className="catagory">
                            {program.image}
                            <span>{program.heading}</span>
                            <span>{program.details}</span>
                            <div className="joinNow">
                                <span>Join Now</span>
                                <img src={rightArrow} alt="" />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Program
import React from 'react'
import './Plan.css'
import { plansData } from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
const Plan = () => {
    return (
        <div className='planContainer' id='plan'>
            <div className="blur blur-p1"></div>
            <div className="blur blur-p2"></div>
            <div className="pragramHeader" >
                <span className='stroke-text' style={{ gap: '2rem' }}>READY TO START</span>
                <span> YOUR JOURNEY </span>
                <span className='stroke-text' style={{ gap: '2rem' }}>NOW WITH US</span>
            </div>
            <div className="plans">
                {plansData.map((plan, i) => {
                    return (
                        <>
                            <div className="plan" key={i}>
                                {plan.icon}
                                <span>{plan.name}</span>
                                <span>$ {plan.price}</span>
                                <div className="features">
                                    {plan.features.map((features, i) => {
                                        return (
                                            <>
                                                <div className="feature">
                                                    <img src={whiteTick} alt="" />
                                                    <span key={i}>{features}</span>
                                                </div>
                                            </>)
                                    })}
                                </div>
                                <div className='seeMore'>
                                    <span>See more benefits -</span>
                                    <div>
                                    
                                    </div>
                                    <button className="btn">Join now</button>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default Plan
import React, { useState } from 'react'
import "./Header.css"
import Logo from "../../assets/logo.png";
import bars from '../../assets/bars.png'
import { Link } from 'react-scroll';

const Header = () => {
  const mobile = window.innerWidth<=768 ?true :false;
  const [menuopen,setMenuopen] = useState(false);
  return (
    <div className="header">
        <img src={Logo} alt="" className='Logo' />
      {(menuopen===false && mobile === true)
       ?<div style={{backgroundColor:'#3c3f45',padding:'0.5rem', borderRadius:'10%'}}>
        <img src={bars} alt=""
        style={{width :'1.5rem',height:'1.5rem'}} 
        onClick={()=>setMenuopen(true)}/>
        </div> 
    
        :<ul className='header-menu'> 
            <li ><Link onClick={()=>setMenuopen(false)} to='home'  span={true} smooth={true}>Home</Link></li>
            <li ><Link onClick={()=>setMenuopen(false)} to='program' span={true} smooth={true}>Program</Link></li>
            <li ><Link onClick={()=>setMenuopen(false)} to='reason' span={true} smooth={true}>Why us</Link></li>
            <li ><Link onClick={()=>setMenuopen(false)} to='plan' span={true} smooth={true}>Plans</Link></li>
            <li ><Link onClick={()=>setMenuopen(false)} to='Testimons' span={true} smooth={true}>Testimonials</Link></li>
        </ul>
      }
    </div>
  )
}

export default Header
import React from 'react'
import'./Testimon.css'
import {testimonialsData} from '../../data/testimonialsData'
import { useState } from 'react'
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'
import {motion} from 'framer-motion'


const Tesimon = () => {
    const [selected,setSelected] = useState(0);
    const tLen = testimonialsData.length;
    const transition = {type :"spring",duration :2}
  return (
    <div className='Testimons' id='Testimons'>
        <div className="left-t">
            <span>Testimonials</span>
            <span className='stroke-text'>What they</span>
            <span>say about us</span>
            <motion.span
            initial = {{opacity:0, x :-100}}
            animate = {{opacity:1, x :0}}
            transition = {{...transition,duration:2}}
            exit={{opacity:0, x :100}} 
            key={selected}>
                {testimonialsData[selected].review}
            </motion.span>
            <span>
                <span style={{color:"#f48915"}}>
                    {testimonialsData[selected].name}
                </span>{ " "}
                - {testimonialsData[selected].status}
            </span>
        </div>
        <div className="right-t">
            <motion.div 
            initial = {{opacity:0, x :-100}} 
            whileInView = {{opacity:1, x :0}}
            transition = {{...transition,duration:2}}></motion.div>
            <motion.div
            initial = {{opacity:0, x :100}} 
            whileInView = {{opacity:1, x :0}}
            transition = {{...transition,duration:2}}></motion.div>
            <motion.img 
            initial = {{opacity:0, x :100}} 
            animate = {{opacity:1, x :0}}
            transition = {{...transition,duration:2}}
            exit={{opacity:0, x :-100}}
            key={selected}
            src={testimonialsData[selected].image} alt="" />
            <div className="arrows">
                <img src={leftArrow} alt="" onClick={()=>{
                    selected === 0
                        ? setSelected(tLen-1)
                        : setSelected((prev)=>prev-1)  
                }}/>
                <img src={rightArrow} alt="" onClick={()=>{
                    selected === tLen-1
                        ? setSelected(0)
                        : setSelected((prev)=>prev+1)  
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Tesimon
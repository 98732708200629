import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import heart from '../../assets/heart.png';
import calories from '../../assets/calories.png';
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

const Hero = () => {
    const transition = {type :"spring",duration :2};
    const mobile = window.innerWidth<=768 ?true :false;
  return (
    <div className='hero' id='home'>
        <div className="blur blur-h"></div>
        <div className="left-h">
            <Header/>
            <div className="bestAd">
                <motion.div initial = {{left:mobile ?'180px':'238px'}} 
                    whileInView = {{left:'8px'}}
                    transition = {{...transition,type: 'tween'}} >                   
                </motion.div>
                <span>The best Fitness Club in the town</span>
            </div>
            <div className="heroText">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>ideal body</span>
                </div>
                <div>
                    <span>in here we will help you to shape and build your ideal body and live up your life to fullest</span>
                </div>
            </div>
            <div className="figures">
                <div>
                    <span><NumberCounter end={140} start={100} delay='4' preFix="+" /></span>
                    <span>Expert coachs</span>
                </div>
                <div>
                    <span><NumberCounter end={978} start={800} delay='4' preFix="+" /></span>
                    <span>Members</span>
                </div>
                <div>
                    <span><NumberCounter end={50} start={30} delay='4' preFix="+" /></span>
                    <span>Fitness Programs</span>
                </div>
            </div>
            <div className="heroBtn">
                <button className="btn">Get Started</button>
                <button className="btn">Learn More</button>
            </div>
        </div>
        <div className="rigt-h" style={{backgroundColor: "#f48915",flex: 1}}>
            <button className='btn btn-h' style={{position: "relative",right: "-14rem",top: "2rem",color: "black"}}>Join Now</button>
            <motion.div className="heart-rate" 
                    initial = {{right:'-1rem'}} 
                    whileInView = {{right:'4rem'}}
                    transition = {transition}>
                <img src={heart} alt="" className=''/>
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </motion.div>
            <img src={hero_image} alt="" className='hero_image'/>
            <motion.img initial = {{right:'11rem'}} 
            whileInView = {{right:'20rem'}}
            transition = {transition} src={hero_image_back} alt="" className='hero_image_back'/>
            <motion.div className='calories' 
            initial = {{right:'37rem'}} 
            whileInView = {{right:'28rem'}}
            transition = {transition}>
                <img src={calories} alt="" />
                <div>
                <span>Calories Burned</span>
                <span>220 kcal</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}

export default Hero
import './App.css';
import Hero from './Components/Hero/Hero';
import Program from './Components/Programs/Program';
import Reason from ".//Components/Reasons/Reason";
import Plan from ".//Components/Plans/Plan";
import Testimon from './/.//Components//TestiMon//Tesimon'
import Join from ".//Components/Join/Join";
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className="App">
          <Hero/>
          <Program/>
          <Reason/>
          <Plan/>
          <Testimon/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
